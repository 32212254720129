<template>
  <div class="appointList">
    <div class="tab">
      <div :class="['tab-l', { active: oIndex == 1 }]" @click="select(1)">
        租赁申请
      </div>
      <div :class="['tab-r', { active: oIndex == 2 }]" @click="select(2)">
        场馆预约
      </div>
    </div>
    <div class="tab2" v-if="oIndex == 2">
      <span :class="['tag', { active: oIndex1 == 1 }]" @click="selectAppointmentType(1)">个人预约</span>
      <span :class="['tag', { active: oIndex1 == 2 }]" @click="selectAppointmentType(2)">团队预约</span>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="areaAppointItem"
        @click="toDetail(item)"
      >
        <div class="listData-l" v-if="oIndex == 2">
          <div class="appointList-top">
            <div class="order">
              <div class="item-l">工单号：</div>
              <div class="item-r">{{ item.orderId }}</div>
            </div>
            <div :class="('status', { active: item.status == 2 })">
              {{ getStatus(item) }}
            </div>
          </div>
          <div class="appointList-t">
            <div class="appointList-tl"><img :src="item.picUrl" alt="" /></div>
            <div class="appointList-tr">
              <div class="name">
                {{ item.placeName }}
              </div>
              <div class="address">{{ item.address }}</div>
            </div>
          </div>
          <div class="appointList-b">
            <div class="orderTime">
              <div class="item-l">下单时间：</div>
              <div class="item-r">{{ item.referTime }}</div>
            </div>
            <div class="times">
              <div class="item-l">预约场次：</div>
              <div class="item-r">
                <div
                  class="scheduleList"
                  v-for="(oItem, oIndex) in item.scheduleList"
                  :key="oIndex"
                >
                  <p class="p-l">{{ oItem.strDateTime }}</p>
                  <p class="p-r">{{ oItem.strTimeStatus }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="listData-r" v-if="oIndex == 1">
          <!-- <div class="appointList-top">
            <div class="order">
              <div class="item-l">工单号：</div>
              <div class="item-r">{{ item.orderId }}</div>
            </div>
            <div :class="('status', { active: item.status == 2 })">
              {{ getStatus(item) }}
            </div>
          </div> -->
          <div class="appointList-t">
            <div class="appointList-tl"><img :src="item.picture" alt="" /></div>
            <div class="appointList-tr">
              <div class="name">{{ item.typeName }} {{ item.spaceName }}</div>
              <div class="address">
                {{ item.size }}m² | {{ item.direction }}
              </div>
            </div>
          </div>
          <div class="appointList-b">
            <div class="orderTime">
              <div class="item-l">提交时间：</div>
              <div class="item-r">{{ item.applyDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myAppointUrl, myrentUrl } from "./api.js";
import { appointStatus } from "./map.js";
import { getOption } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "appointList",
  data() {
    return {
      oIndex: 1,
      oIndex1: 1,
      finishedText: "没有更多了",
      finished: false,
      loading: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {},
  methods: {
    selectAppointmentType(data){
      this.oIndex1= data
      this.listData = [];
      this.requestData.curPage = 1;
      this.getList();
    },
    select(data) {
      this.oIndex = data;
      this.listData = [];
      this.requestData.curPage = 1;
      this.getList();
    },
    getStatus(item) {
      if (item.status) {
        console.log(
          88,
          item,
          getOption(item.status, appointStatus, "key").value
        );
        return getOption(item.status, appointStatus, "key").value;
      } else {
        return "";
      }
    },
    getList() {
      if (this.oIndex == 1) {
        this.getMyRent();
      } else {
        this.getMyAppointList();
      }
    },
    onLoad() {
      this.getList();
    },
    getMyRent() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        openId: this.openId,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${myrentUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.referTime) {
              item.referTime = moment(item.referTime).format("YYYY-MM-DD");
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    getMyAppointList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        orderSign:this.oIndex1,
        userId: this.userId,
      };
      this.$axios.get(`${myAppointUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.referTime) {
              item.referTime = moment(item.referTime).format("YYYY-MM-DD");
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    toDetail(item) {
      if (this.oIndex == 2) {
        console.log(this.oIndex1);
        this.$router.push({
          name: "appointDetail",
          query: { orderId: item.orderId,type:this.oIndex1 },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.appointList {
  box-sizing: border-box;
  min-height: 100vh;
  background: #fcfcfc;
  padding-top: 140px;
  .tab2 {
    padding-left: 36px;
    box-sizing: border-box;
    width: 100%;
    height: 96px;
    .tag {
      margin-right: 38px;
      display: inline-block;
      width: 192px;
      height: 62px;
      background: #F5F5F5;
      border-radius: 8px;
      line-height: 62px;
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      color: #323334;
    }
    .active {
      background-color: #007eff;
      color:#fff;
    }
  }
  .tab {
    display: flex;
    background: #fff;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    .tab-l,
    .tab-r {
      flex: 1;
      text-align: center;
      padding: 30px 0;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
      position: relative;
    }
    .active {
      font-weight: 700;
      position: relative;
      &:after {
        content: "";
        width: 64px;
        height: 8px;
        background: #007eff;
        border-radius: 4px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .areaAppointItem {
    background: #fff;
    margin-bottom: 32px;
  }
  .appointList-top {
    padding: 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    font-weight: 400;
    color: #97999b;
    line-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .order {
      display: flex;
      align-items: center;
    }
    .status {
      font-size: 24px;

      color: #97999b;
    }
    .active {
      color: #009aff;
    }
  }
  .appointList-t {
    display: flex;
    position: relative;
    padding: 32px;
    .appointList-tl {
      width: 112px;
      height: 112px;
      margin-right: 16px;
      border-radius: 8px;
      img {
        width: 100%;
        border-radius: 8px;
        height: 100%;
      }
    }
    .appointList-tr {
      flex: 1;
      padding: 8px 0 0;
      font-size: 30px;
      .name {
        font-size: 32px;
        font-weight: bold;
        color: #323334;
        line-height: 44px;
      }
      .name,
      .address {
        width: 520px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .address {
        margin: 12px 0;
        font-size: 28px;
        font-weight: 400;
        color: #97999b;
        line-height: 40px;
      }
    }
  }
  .appointList-b {
    padding: 0 32px 32px 32px;
    .orderTime {
      margin: 0 0 16px 0;
    }
    .order,
    .orderTime,
    .times {
      display: flex;
      font-size: 28px;
      .item-l {
        width: 150px;
        font-size: 28px;
        font-weight: 400;
        color: #323334;
        line-height: 40px;
      }
      .item-r {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #97999b;
        line-height: 40px;
      }
    }
    .times {
      .scheduleList {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
